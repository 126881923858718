import React, { useMemo } from 'react'

import { SmartTable, SmartTableColumn, SmartTableProps } from '@percept/app-components'

import { CellRenderers, Column, ProviderLogo, SimpleTable, makeAppStyles } from '@percept/mui'

import { DerivedMediaQualityRateRow, MediaQualityRateProvider } from './hook'

import { rateFormatter } from '@percept/mui/charts'

import { separateThousands } from '@percept/utils'


const mediaQualityRateTableColumns: SmartTableColumn<DerivedMediaQualityRateRow>[] = [
  {
    key: 'source_provider',
    name: 'Provider',
    pinned: true,
    renderShortcut: 'provider',
  },
  {
    key: 'placement',
    name: 'Placement',
  },
  {
    key: 'total_impressions',
    name: 'Total Impressions',
    renderShortcut: 'numeric',
  },
  {
    key: 'verifiable_impressions',
    name: 'Verifiable Impressions',
    renderShortcut: 'numeric',
  },
  {
    key: 'monitored_impressions',
    name: 'Monitored Impressions',
    renderShortcut: 'numeric',
  },
  {
    key: 'authentic_impressions',
    name: 'Authentic Impressions',
    renderShortcut: 'numeric',
  },
  {
    key: 'doubleverify_tracking_usage_rate',
    name: 'DoubleVerify Tracking Usage Rate',
    renderShortcut: 'rate-based',
  },
  {
    key: 'media_quality_rate',
    name: 'Media Quality Rate',
    renderShortcut: 'rate-based',
  },
]


export type MediaQualityRateTableProps = {
  data: DerivedMediaQualityRateRow[]
  title?: React.ReactNode
}

const smartTableOptions: SmartTableProps<DerivedMediaQualityRateRow>['options'] = {
  // tableBodyMaxHeight: 'calc(100vh - 264px)',
  tableBodyMaxHeight: 'calc(100vh - 218px)',
}

export const MediaQualityRateSmartTable = ({
  data,
  title = 'Media Quality Rate',
}: MediaQualityRateTableProps) => {
  return (
    <SmartTable
      title={title}
      columns={mediaQualityRateTableColumns}
      items={data}
      options={smartTableOptions} />
  )
}

type MediaQualityRateTableRow = DerivedMediaQualityRateRow & {
  label: string
}

const columns: Column<MediaQualityRateTableRow>[] = [
  {
    key: 'label',
    label: 'Platform / Placement',
    align: 'left',
    tooltip: 'Platform / placement type within each platform the ad activity was served on',
  },
  {
    key: 'total_impressions',
    label: 'Total Impressions',
    align: 'right',
    tooltip: 'Total impressions delivered per platform / placement type within each platform',
  },
  {
    key: 'verifiable_impressions',
    label: 'Verifiable Impressions',
    align: 'right',
    tooltip: <>
      Impressions delivered per platform / placement type within each platform that are eligible to be
      tracked by DoubleVerify
    </>
  },
  {
    key: 'monitored_impressions',
    label: 'Monitored Impressions',
    align: 'right',
    tooltip: 'Monitored impressions as tracked by DoubleVerify',
  },
  {
    key: 'blocked_impressions',
    label: 'Blocked Impressions',
    align: 'right',
    tooltip: 'Blocked impressions as tracked by DoubleVerify',
  },
  {
    key: 'authentic_impressions',
    label: 'Authentic Impressions',
    align: 'right',
    tooltip: 'Authentic impressions as tracked by DoubleVerify',
  },
  {
    key: 'doubleverify_tracking_usage_rate',
    label: 'DoubleVerify Tracking Usage Rate',
    align: 'right',
    tooltip: <>
      Represents the % of eligible impressions utilising DoubleVerify tracking using the formula:
      Monitored Impressions + Blocked Impressions / Verifiable Impressions
    </>,
  },
  {
    key: 'media_quality_rate',
    label: 'Media Quality Rate',
    align: 'right',
    tooltip: <>
      Represents the % of all eligible impressions delivered that were deemed to be Authentic by DoubleVerify
      using the formula: Authentic Impressions / Verifiable Impressions
    </>,
  },
]

const rowAdapter = (row: DerivedMediaQualityRateRow, labelKey: keyof DerivedMediaQualityRateRow): MediaQualityRateTableRow => ({
  ...row,
  label: String(row[labelKey]),
})

const rateColumns: (keyof MediaQualityRateTableRow)[] = [
  'media_quality_rate',
  'doubleverify_tracking_usage_rate',
]

const numericColumns: (keyof MediaQualityRateTableRow)[] = [
  'authentic_impressions',
  'monitored_impressions',
  'blocked_impressions',
  'total_impressions',
  'verifiable_impressions',
]

const displayNA = <span style={{opacity: 0.5}}>N / A</span>

const nullFormatter = (value: string | number | null, formatter: (value: number) => string) => {
  if( value === null ){
    return displayNA
  }
  return formatter(Number(value))
}

const allMQRProviders: MediaQualityRateProvider[] = [
  'ADFORM', 'AMAZON_ADS', 'DV360', 'FACEBOOK', 'GOOGLE_ADS'
]

const renderers: CellRenderers<MediaQualityRateTableRow> = {
  label: ({ label, placement }) => {
    if( allMQRProviders.includes(label as MediaQualityRateProvider) && !placement ){
      return <ProviderLogo provider={label as MediaQualityRateProvider} size={1.35} />
    }
    return <>{placement}</>
  },
  ...rateColumns.reduce( (acc, column) => {
    acc[column] = row => <>{nullFormatter(row[column], rateFormatter)}</>
    return acc
  }, {} as CellRenderers<MediaQualityRateTableRow>),
  ...numericColumns.reduce( (acc, column) => {
    acc[column] = row => <>{nullFormatter(row[column], separateThousands)}</>
    return acc
  }, {} as CellRenderers<MediaQualityRateTableRow>),
}


export const useTableStyles = makeAppStyles( theme => ({
  stickyHeader: {
    background: theme.palette.background.paper,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -8,
      left: 0,
      width: '100%',
      height: 8,
      background: `linear-gradient(to bottom, ${
        'rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%'
      })`,
      zIndex: 2,
    }
  },
}))


export const MediaQualityRateTable = ({
  rowsByProvider,
  rowsByPlacement,
  onRowExpand,
  onRowCollapse,
}: {
  rowsByProvider: DerivedMediaQualityRateRow[] | undefined
  rowsByPlacement: DerivedMediaQualityRateRow[] | undefined
  onRowExpand?: (row: MediaQualityRateTableRow) => void
  onRowCollapse?: (row: MediaQualityRateTableRow) => void
}) => {
 
  const { rows, placementRows } = useMemo(() => {
    return {
      rows: (rowsByProvider || []).map(r => rowAdapter(r, 'source_provider')),
      placementRows: (rowsByPlacement || []).map(r => rowAdapter(r, 'placement')),
    }
  }, [rowsByProvider, rowsByPlacement])
  
  const classes = useTableStyles()

  return (
    <SimpleTable
      sortable
      stickyHeader
      unsetStickyHeaderZIndex={false}
      wrapCellText={false}
      pinFirstColumn
      size='small'
      classes={classes}
      TableContainerProps={{
        style: {
          maxHeight: 'calc(100vh - 210px)',
        }
      }}
      columns={columns}
      rows={rows}
      renderers={renderers}
      getRowKey={row => row.placement || row.source_provider}
      onRowExpand={onRowExpand}
      onRowCollapse={onRowCollapse}
      grouped={true}
      getRowGroup={ row => {
        const { source_provider } = row
        return placementRows.filter( r => r.source_provider === source_provider )
      }} />
  )
}
