import React, { useEffect, useMemo, useState } from 'react'

import {
  BackdropLoader,
  Box,
  Card,
  DateRangeValue,
  Divider,
  FormLabel,
  LinearProgress,
  MultiPlatformUnitSelect,
  MultiProviderSelect,
  RoundedPlainTextButtonMenu,
  SimpleTable,
  SimpleTableProps,
  Typography,
  makeAppStyles,
} from '@percept/mui'
import { ArrowDropDown } from '@percept/mui/icons'

import { useUserPlatformUnits } from '@percept/hooks'
import { useTargetCurrency } from 'hooks'
import {
  DataWarehouseProvider,
  DataWarehouseReportGrouping,
  DataWarehouseReportGroupingBase,
  useDataWarehouseQuery,
  useDataWarehouseQueryGroupings,
} from './hooks'

import { useActivePlatformUnit } from 'components/Organisation'

import { CurrencySelect } from 'components/CurrencySelect'
import { dateRangeCalculators, DateRangePreset } from 'components/MediaQualityRate/lib'
import { DateRangePopover } from 'components/MediaQualityRate/DateRangePopover'
import { GenericTableRow, getDataWarehouseTableProps } from './lib'
import { useTableStyles } from 'components/MediaQualityRate/MediaQualityRateTable'

import { flatten, maxBy } from 'lodash-es'

import { PlatformUnit } from '@percept/types'


const dataWarehouseProviders: DataWarehouseProvider[] = [
  'GOOGLE_ADS', 'FACEBOOK', 'ADFORM'
]


const minDatesByProvider: Partial<Record<DataWarehouseProvider, Date>> = {
  GOOGLE_ADS: new Date('2018-01-01'),
  ADFORM: new Date('2018-01-01'),
  FACEBOOK: new Date('2019-10-03')
}

const minDate = maxBy(Object.values(minDatesByProvider), d => d.getTime())

const maxDate = new Date('2024-10-01')

const useStyles = makeAppStyles( theme => ({
  filterSelector: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  filterLabel: {
    fontSize: 11,
    marginLeft: 10,
    marginBottom: 6,
  },
  groupingTitle: {
    fontSize: 14,
  },
  groupingExplainer: {
    marginTop: 2,
    fontSize: 12,
    lineHeight: 1.5,
    maxWidth: '21rem',
    whiteSpace: 'normal',
  },
  tableCard: {
    position: 'relative'
  },
  refetchIndicator: {
    height: 4,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar + 4,
  },
}))

export const DataWarehouse = () => {

  const groupingsQueryResult = useDataWarehouseQueryGroupings()

  const activePlatformUnit = useActivePlatformUnit()

  const [grouping, setGrouping] = useState<DataWarehouseReportGrouping | null>(null)
  const [sub_grouping, setSubGrouping] = useState<DataWarehouseReportGroupingBase | null>(null)

  const [dateRangePreset, setDateRangePreset] = useState<DateRangePreset | 'custom'>('current-financial-year')

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(() => (
    maxDate === null ?
      [null, null] :
      dateRangeCalculators['current-financial-year'](maxDate)
  ))

  const [targetCurrency,] = useTargetCurrency()

  const [orgUnitHierarchy] = useUserPlatformUnits()

  const [selectedPlatformUnits, setSelectedPlatformUnits] = useState<PlatformUnit[]>([])

  const [selectedProviders, setSelectedProviders] = useState<DataWarehouseProvider[]>(dataWarehouseProviders)

  const availableOrgUnits = flatten(
    (orgUnitHierarchy.data || []).map( org => [
      org,
      ...(org.children || [])
    ])
  )

  useEffect(() => {
    if( !selectedPlatformUnits.length && activePlatformUnit ){
      setSelectedPlatformUnits([activePlatformUnit])
    }
  }, [activePlatformUnit, selectedPlatformUnits])

  useEffect(() => {
    if( !grouping && groupingsQueryResult.data && groupingsQueryResult.data.length === 1 ){
      const defaultGrouping = groupingsQueryResult.data[0]
      if( defaultGrouping.sub_groupings && defaultGrouping.sub_groupings.length ){
        setSubGrouping(defaultGrouping.sub_groupings[0])
      }
      setGrouping(defaultGrouping)
    }
  }, [grouping, groupingsQueryResult.data])

  const queryResult = useDataWarehouseQuery({
    grouping: grouping && grouping.key,
    sub_grouping: sub_grouping && sub_grouping.key,
    org_unit_ids: selectedPlatformUnits.map( u => u.id),
    providers: selectedProviders,
    start: dateRange && dateRange[0],
    end: dateRange && dateRange[1],
    currency: targetCurrency,
  })

  const tableProps: SimpleTableProps<GenericTableRow> = useMemo(() => {
    if( !queryResult.data ){
      return {
        grouped: false,
        columns: [],
        rows: [],
      } as unknown as SimpleTableProps<GenericTableRow>
    }
    return getDataWarehouseTableProps(queryResult.data, targetCurrency) as SimpleTableProps<GenericTableRow>
  }, [queryResult.data, targetCurrency])

  const classes = useStyles()

  const tableClasses = useTableStyles()

  return (
    <Box px={3} pt={5}>

      <Box display='flex' alignItems='center' mb={5}>
        <div className={classes.filterSelector}>
          <FormLabel className={classes.filterLabel}>Report Type</FormLabel>
          <RoundedPlainTextButtonMenu
            TriggerProps={{
              variant: 'contained',
              size: 'small',
              endIcon: <ArrowDropDown />,
            }}
            value={grouping}
            label={grouping ? grouping.title : 'Select Report Type'}
            disabled={!groupingsQueryResult.data}
            options={[
              ...((groupingsQueryResult.data || []).map( grouping => ({
                value: grouping,
                label: grouping.title,
              })))
            ]}
            onChange={(e, value: DataWarehouseReportGrouping): void => {
              setGrouping(value)
              if( value && value.sub_groupings && value.sub_groupings.length ){
                setSubGrouping(value.sub_groupings[0])
              }
            }} />
        </div>

        { !!(grouping && grouping.sub_groupings && grouping.sub_groupings.length) && (
          <div className={classes.filterSelector}>
            <FormLabel className={classes.filterLabel}>Report Behaviour</FormLabel>
            <RoundedPlainTextButtonMenu
              TriggerProps={{
                variant: 'contained',
                size: 'small',
                endIcon: <ArrowDropDown />,
              }}
              value={sub_grouping}
              label={sub_grouping ? sub_grouping.title : 'Select Report Behaviour'}
              options={[
                ...(grouping.sub_groupings.map( sub_grouping => ({
                  value: sub_grouping,
                  label: (
                    <div>
                      <Typography variant='h6' className={classes.groupingTitle}>
                        {sub_grouping.title}
                      </Typography>
                      { sub_grouping.info && (
                        <Typography className={classes.groupingExplainer}>
                          {sub_grouping.info}
                        </Typography>
                      )}
                    </div>
                  ),
                }))),
              ]}
              onChange={(e, value: DataWarehouseReportGroupingBase): void => {
                setSubGrouping(value)
              }} />
          </div>
        ) }

        <div className={classes.filterSelector}>
          <FormLabel className={classes.filterLabel}>Organisations</FormLabel>
          <MultiPlatformUnitSelect
            ButtonPopoverProps={{
              size: 'small'
            }}
            value={selectedPlatformUnits}
            options={availableOrgUnits}
            onChange={setSelectedPlatformUnits} />
        </div>

        <div className={classes.filterSelector}>
          <FormLabel className={classes.filterLabel}>Providers</FormLabel>
          <MultiProviderSelect
            ButtonPopoverProps={{
              size: 'small'
            }}
            value={selectedProviders}
            options={dataWarehouseProviders}
            onChange={setSelectedProviders} />
        </div>

        { dateRange[0] && dateRange[1] && (
          <div className={classes.filterSelector}>
            <FormLabel className={classes.filterLabel}>Date Range</FormLabel>
            <DateRangePopover
              color='primary'
              ButtonProps={{
                color: 'default'
              }}
              dateRangePreset={dateRangePreset}
              setDateRangePreset={setDateRangePreset}
              value={dateRange as DateRangeValue}
              onChange={setDateRange}
              minDate={minDate}
              maxDate={maxDate} />
          </div>
        )}

        <div className={classes.filterSelector}>
          <FormLabel className={classes.filterLabel}>Currency</FormLabel>
          <CurrencySelect
            TriggerProps={{
              variant: 'contained',
              size: 'small',
            }} />
        </div>
      </Box>
      
      { (queryResult.isLoading || !orgUnitHierarchy.data) && <BackdropLoader />}
      { queryResult.data && (
        <Card className={classes.tableCard}>
          { queryResult.isRefetching && (
            <LinearProgress variant='indeterminate' className={classes.refetchIndicator} />
          )}
          <SimpleTable
            size='small'
            sortable
            stickyHeader
            pinFirstColumn
            unsetStickyHeaderZIndex={false}
            classes={tableClasses}
            TableContainerProps={{
              style: {
                maxHeight: 'calc(100vh - 245px)',
              }
            }}
            {...tableProps} />
        </Card>
      )}

    </Box>
  )
}
