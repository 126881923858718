import React, { useState } from 'react'

import {
  Box,
  ButtonPopover,
  ButtonProps,
  DateRangeInput,
  DateRangeInputProps,
  Divider,
  ReportRange,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
} from '@percept/mui'

import { ArrowDropDown, CalendarToday, CalendarViewDay, Schedule } from '@percept/mui/icons'

import { format } from 'date-fns'

import { DateType } from '@percept/types'

import { DateRangePreset, dateRangeCalculators } from './lib'


const areDatesEqual = (a: DateType | null, b: DateType | null): boolean => Boolean(
  a && b && format(new Date(a), 'yyyy-MM-dd') === format(new Date(b), 'yyyy-MM-dd')
)


const dateRangePresetLabels: Record<DateRangePreset | 'custom', string> = {
  'custom': 'Custom',
  'current-financial-year': 'Current Financial Year',
  'current-year': 'Current Year',
  'last-financial-year': 'Last Financial Year',
  'last-year': 'Last Year',
  'last-quarter': 'Last Quarter',
  'last-month': 'Last Month',
  'last-week': 'Last Week',
}

const dateRangePresets: (DateRangePreset | 'custom')[] = [
  'current-financial-year',
  'current-year',
  'last-financial-year',
  'last-year',
  'last-quarter',
  'last-month',
  'last-week',
  'custom',
]


export const DateRangePopover = ({
  dateRangePreset,
  setDateRangePreset,
  value,
  onChange,
  color,
  maxDate,
  ButtonProps = {},
  ...props
}: Omit<DateRangeInputProps, 'onChange' | 'maxDate'> & {
  maxDate: Date | null
  dateRangePreset: DateRangePreset | 'custom'
  setDateRangePreset: (dateRangePreset: DateRangePreset | 'custom') => void
  onChange: (value: [Date, Date]) => void
  ButtonProps?: Partial<ButtonProps>
}): JSX.Element => {
  const [localValue, setLocalValue] = useState(value)

  const [localDateRangePreset, setLocalDateRangePreset] = useState(dateRangePreset)

  const filtersApplied = (
    areDatesEqual(value[0], localValue[0])
    && areDatesEqual(value[1], localValue[1])
  )

  return (
    <ButtonPopover
      buttonContent={
        dateRangePreset === 'custom' ?
          <ReportRange
            start={localValue[0]}
            end={localValue[1]}
            TypographyProps={{
              variant: 'inherit',
            }} /> :
          dateRangePresetLabels[dateRangePreset]
      }
      ButtonComponent={RoundedPlainTextButton}
      endIcon={<ArrowDropDown />}
      onClose={(reason): void => {
        // NOTE - we want to reset local state when the dialog is closed
        // by the popover on click away or `Esc` keypress. However, this
        // close handler is also closed when we apply the filters - so
        // we need to examine the reason given for the close event to
        // avoid setting local state twice and getting out of sync.
        if( reason === 'backdropClick' || reason === 'escapeKeyDown' ){
          setLocalValue(value)
          setLocalDateRangePreset(dateRangePreset)
        }
      }}
      size='small'
      variant='contained'
      color='secondary'
      {...ButtonProps}>
      { ({ onClose }) => (
        <Box p={2}>
          <Box mb={3}>
            <RoundedPlainTextButtonMenu
              TriggerProps={{
                variant: 'contained',
                startIcon: <CalendarToday />,
                endIcon: <ArrowDropDown />,
              }}
              value={dateRangePreset}
              label={dateRangePresetLabels[localDateRangePreset]}
              onChange={(e, value: DateRangePreset | 'custom') => {
                setLocalDateRangePreset(value)
                if( value !== 'custom' && maxDate ){
                  setLocalValue(
                    dateRangeCalculators[value](maxDate)
                  )
                }
              }}
              options={
                dateRangePresets.map( value => ({
                  value,
                  label: dateRangePresetLabels[value],
                }))
              } />
          </Box>
          <DateRangeInput
            variant='static'
            divided
            color={color}
            value={localValue}
            maxDate={maxDate}
            onChange={(dateRange) => {
              setLocalValue(dateRange)
              setLocalDateRangePreset('custom')
            }}
            {...props} />
          <Divider />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <RoundedPlainTextButton
              variant='contained'
              color={color}
              disabled={filtersApplied}
              onClick={(): void => {
                setDateRangePreset(localDateRangePreset)
                onChange([
                  new Date(localValue[0]),
                  new Date(localValue[1]),
                ])
                onClose()
              }}>
              { filtersApplied ? 'Filter Applied' : 'Apply Filter'}
            </RoundedPlainTextButton>
          </Box>
        </Box>
      )}
    </ButtonPopover>
  )
}
